#colorbulle1 {
    animation: animation1 35s ease-in-out infinite alternate;
    top: 0;
    right: 0;
    z-index: -10;

    width: 30vw;
    aspect-ratio: 1/1;

    max-width: 300px;
    opacity: 0.5;

    filter: blur(79px);
}

@keyframes animation1 {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(-60vw, 20vh);
    }
    40% {
        transform: translate(-80vw, 10vh);
    }
    60% {
        transform: translate(-50vw, -20vh);
    }
    80% {
        transform: translate(-5vw, 30vh);
    }
    100% {
        transform: translate(10vw, -10vh);
    }
}

#colorbulle2 {
    animation: animation2 35s ease-in-out infinite alternate;
    top: 0;
    left: 0;

    z-index: -100;

    width: 30vw;
    aspect-ratio: 1/1;

    max-width: 300px;
    opacity: 0.5;
    filter: blur(79px);
}

@keyframes animation2 {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(60vw, 20vh);
    }
    40% {
        transform: translate(80vw, 10vh);
    }
    60% {
        transform: translate(50vw, -20vh);
    }
    80% {
        transform: translate(5vw, 30vh);
    }
    100% {
        transform: translate(-10vw, -10vh);
    }
}
